import { render, staticRenderFns } from "./Alpha.vue?vue&type=template&id=2a5926ce"
import script from "./Alpha.vue?vue&type=script&lang=js"
export * from "./Alpha.vue?vue&type=script&lang=js"
import style0 from "./Alpha.vue?vue&type=style&index=0&id=2a5926ce&lang=css"


/* normalize component */
import normalizer from "!../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/live/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a5926ce')) {
      api.createRecord('2a5926ce', component.options)
    } else {
      api.reload('2a5926ce', component.options)
    }
    module.hot.accept("./Alpha.vue?vue&type=template&id=2a5926ce", function () {
      api.rerender('2a5926ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "@apostrophecms/vue-color/src/components/common/Alpha.vue"
export default component.exports