import { render, staticRenderFns } from "./AposCheckbox.vue?vue&type=template&id=154fb6be&scoped=true"
import script from "./AposCheckbox.vue?vue&type=script&lang=js"
export * from "./AposCheckbox.vue?vue&type=script&lang=js"
import style0 from "./AposCheckbox.vue?vue&type=style&index=0&id=154fb6be&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154fb6be",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/live/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('154fb6be')) {
      api.createRecord('154fb6be', component.options)
    } else {
      api.reload('154fb6be', component.options)
    }
    module.hot.accept("./AposCheckbox.vue?vue&type=template&id=154fb6be&scoped=true", function () {
      api.rerender('154fb6be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/ui/apos/components/AposCheckbox.vue"
export default component.exports