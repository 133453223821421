import { render, staticRenderFns } from "./AposTiptapDivider.vue?vue&type=template&id=23f0af64&scoped=true"
import script from "./AposTiptapDivider.vue?vue&type=script&lang=js"
export * from "./AposTiptapDivider.vue?vue&type=script&lang=js"
import style0 from "./AposTiptapDivider.vue?vue&type=style&index=0&id=23f0af64&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f0af64",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/live/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23f0af64')) {
      api.createRecord('23f0af64', component.options)
    } else {
      api.reload('23f0af64', component.options)
    }
    module.hot.accept("./AposTiptapDivider.vue?vue&type=template&id=23f0af64&scoped=true", function () {
      api.rerender('23f0af64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "rich-text-widget/ui/apos/components/AposTiptapDivider.vue"
export default component.exports