import { render, staticRenderFns } from "./AposDocsManagerDisplay.vue?vue&type=template&id=4f2e509a&scoped=true"
import script from "./AposDocsManagerDisplay.vue?vue&type=script&lang=js"
export * from "./AposDocsManagerDisplay.vue?vue&type=script&lang=js"
import style0 from "./AposDocsManagerDisplay.vue?vue&type=style&index=0&id=4f2e509a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f2e509a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/live/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f2e509a')) {
      api.createRecord('4f2e509a', component.options)
    } else {
      api.reload('4f2e509a', component.options)
    }
    module.hot.accept("./AposDocsManagerDisplay.vue?vue&type=template&id=4f2e509a&scoped=true", function () {
      api.rerender('4f2e509a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "piece-type/ui/apos/components/AposDocsManagerDisplay.vue"
export default component.exports